<template>
    <div id="bundle" v-if="bundle">
        <div class="uk-text-left">
            <h2 class="uk-text-left uk-width-auto"><a @click="$router.go(-1)" style="text-decoration: none;" class="uk-flex media-header-text"><span uk-icon="icon: chevron-left; ratio: 1.9"></span><span class="uk-text-truncate uk-width-4-5">{{bundle.name}}</span></a></h2>
        </div>
        <IconicPlayer v-if="isVideo" class="uk-margin-medium-bottom uk-text-left uk-flex iconic-player" :media="media" :orgId="orgId"/>
        <div class="uk-container uk-width-1-1 uk-card-default uk-card-large uk-margin-remove-left">
            <div class="uk-card-body uk-padding-remove uk-margin-small-top">
                <div class="uk-flex">
                    <h3 class="uk-card-title uk-text-left uk-padding-remove uk-width-expand"> {{ $t('Pages.MediaLibrary.Labels.Details') }} </h3>
                    <div>
                        <span v-if="!edit" uk-icon="icon: pencil" style="cursor: pointer;"  @click="edit = true"> </span>
                    </div>
                </div>
                <div class="uk-card-body uk-text-left uk-padding-remove uk-margin-small-top uk-margin-small-left uk-flex uk-width-1-1">
                    <div class="uk-width-1-3 uk-margin-right">
                        <div>
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Title') }} 
                                <span class="uk-text-danger">*</span>
                            </label>
                            <div v-if="!edit" class="uk-text-break">
                                <span class="uk-text-bold uk-text-left"> {{ bundle.name }} </span>
                            </div>
                            <div v-else>
                                <div class="uk-inline uk-width-1-1 uk-margin-small-top">
                                    <input class="uk-input" :class="{  'invalid-border': !invalidName }" type="text" :placeholder="$t('Pages.MediaLibrary.Placeholders.Title')" v-model.trim="title" />
                                    <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="!invalidName" uk-icon="icon: warning" ></span>
                                    <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!invalidName" >{{$t('Pages.MediaLibrary.inValidTitleError') }}</span>
                                </div>
                            </div>
                        </div>
                        <div class="uk-margin-medium">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Description') }} </label>
                            <div v-if="!edit" class="uk-text-break">
                                <span class="uk-text-bold uk-text-left"> {{ bundle.masterVideo.description ||  'None' }}   </span>
                            </div>
                            <div v-else>
                                <textarea style="min-height: 80px" class="uk-input uk-margin-small-top" type="text" :placeholder="$t('Pages.MediaLibrary.Placeholders.Description')" v-model.trim="description"/>
                            </div>
                        </div>
                        <div v-if="!edit" class="uk-margin-medium">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Created') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ bundle.masterVideo.createdAt | date }} </span>
                            </div>
                        </div>
                        <div v-if="!edit" class="uk-margin-medium">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Size') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ getMediaSizeBytes( bundle.masterVideo ) | appropriateBytes }} </span>
                            </div>
                        </div>
                    </div>
                    <div class="uk-width-1-3 uk-margin-right">
                        <div>
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.ProjectionType') }} </label>
                            <div v-if="!edit">
                                <span class="uk-text-bold uk-text-left"> {{ $t( `enums.ProjectionType.${bundle.masterVideo.projection}` ) }} </span>
                            </div>
                            <div v-else class="uk-margin-small-top">
                                <div class="uk-width-large">
                                    <select class="uk-select" v-model="selectProjectionType">
                                        <option v-for="type in ProjectionType.values"  :key="type" :value="type">{{ $t( `enums.ProjectionType.${type}` ) }}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div v-if="!edit" class="uk-margin-medium">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Resolution') }} </label>
                            <div>
                                <span class="uk-text-bold uk-text-left"> {{ bundle.masterVideo.resolution.width }} x {{ bundle.masterVideo.resolution.height }} </span>
                            </div>
                        </div>
                        <div v-if="isLive" class="uk-margin-medium">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.StartTime') }} </label>
                            <div v-if="!edit">
                                <span class="uk-text-bold uk-text-left"> {{ liveStartTime | formatDate( 'MMM, DD, yyyy hh:mm a' ) }} </span>
                            </div>
                            <div v-else> 
                                <InputDatePicker
                                    id="start"
                                    v-model="liveStartTime"
                                    class="uk-margin-small-right uk-margin-small-top"
                                    enable-time
                                    handle-utc
                                    :min-date="minDate"
                                    :placeholder="$t('Pages.MediaLibrary.PageUpload.StartPlaceholder')"
                                    required
                                    @input="startTimeInput"
                                />
                                <div class="uk-text-danger uk-text-left" v-if="isEmptyStartTime">{{ $t('Generic.Errors.MandatoryField')}}
                                    <span class="invalid-icon" v-if="isEmptyStartTime" uk-icon="icon: warning"></span>
                                </div>
                            </div>
                            </div>
                           
                        <div v-if="isLive" class="uk-margin-medium">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.EndTime') }} </label>
                            <div v-if="!edit">
                                <span class="uk-text-bold uk-text-left"> {{ liveEndTime | formatDate( 'MMM, DD, yyyy hh:mm a' ) }} </span>
                            </div>
                            <div v-else> 
                                <InputDatePicker
                                    id="end"
                                    v-model="liveEndTime"
                                    class="uk-margin-small-right uk-margin-small-top"
                                    enable-time
                                    handle-utc
                                    :min-date="minEndDate"
                                    :placeholder="$t('Pages.MediaLibrary.PageUpload.EndPlaceholder')"
                                    required
                                    @input="endTimeInput"
                                />
                            </div>
                             <div class="uk-text-danger uk-text-left" v-if="!isEmptyStartTime &&validateLiveTimes">{{ $t('Pages.MediaLibrary.PageUpload.DateValidation')}}
                                <span class="invalid-icon" v-if="!isEmptyStartTime &&validateLiveTimes" uk-icon="icon: warning"></span>
                            </div>
                        </div>
                    </div>
                    <div v-if="!edit && hasLinkedContentOrAssets" class="uk-width-1-3">
                        <div class="uk-padding-remove uk-width-1-1" v-if="mediaAssets.length > 0">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.LinkedAssets') }} </label>
                            <ul class="uk-padding-remove uk-margin-remove" v-for="(asset,i) in mediaAssets" :key="i">
                                <router-link  type="button" :to="assetRedirection(asset)" class="uk-link uk-float-left source-media name-overflow uk-width-1-1">{{asset.name}}</router-link>
                            </ul>
                        </div>
                        <div class="uk-padding-remove uk-width-1-1" v-if="media.linkedContent.length > 0">
                            <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.LinkedContent') }} </label>                            
                            <ul class="uk-padding-remove uk-margin-remove" v-for="(content,i) in media.linkedContent" :key="i">
                                <router-link type="button" :to="{ name: 'ContentDetails', params: { id: orgId, propertyId: media.linkedContent[i].collection.property.id, contentId: media.linkedContent[i].id } }" class="uk-link uk-float-left source-media name-overflow uk-width-1-1">{{content.primaryLanguage.title}}</router-link>
                            </ul>
                        </div>
                    </div>
                    <div v-else-if="!edit" class="uk-width-1-3" >
                        <label class="uk-text-meta uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.LinkedContentOrAssets') }} </label>
                        <p class="uk-text-bold uk-margin-remove"> {{ $t('Pages.MediaLibrary.NoMediaLinked') }} </p>
                    </div>
                </div>
                <div v-if="edit" class="uk-align-right uk-margin-bottom">
                    <button class="uk-button uk-button-default uk-margin-small-right" @click="cancel"> {{ $t('Actions.Cancel') }} </button>
                    <button v-if="!saving" class="uk-button uk-button-primary uk-margin-small-right" @click="updateMediaBundle"> {{ $t('Actions.Save') }} </button>
                    <button v-else class="uk-button uk-button-primary uk-margin-small-right" disabled><div uk-spinner="ratio: 0.5"></div></button>
                </div>
            </div>
        </div>
        <div class="uk-container uk-width-1-1 uk-card-default uk-card-large uk-margin-small-top uk-margin-remove-left">
            <h3 class="uk-card-title uk-text-left uk-padding-remove uk-margin-small-top"> {{$t('Pages.MediaLibrary.Formats')}} </h3>
            <div class="uk-flex uk-margin-small-top">
                <button v-if="featureSupport && featureSupport.transcoding && internal && isVideo" class="uk-button uk-button-primary uk-margin-small-right" type="button" :disabled="transcodableFormatList.length === 0" @click="transcodeMedia"> {{ $t( 'Actions.Transcode' ) }} </button>
                <button v-if="isLive" class="uk-button uk-button-primary uk-margin-small-right" type="button" uk-toggle="target: #add-camera-modal"> {{ $t( 'Pages.MediaLibrary.AddCamera' ) }} </button>
                <button v-if="isVideo" class="uk-button uk-button-primary uk-margin-small-right" type="button" @click="reprobeMaster"> {{ $t( 'Actions.Reprobe' ) }} </button>
            </div>
            <div v-if="( isMediaProbeInProgress( master ) || isReprobeInProgress ) && checkCreatedDate( master )" class="uk-text-left uk-margin-small"> {{ $t( 'Pages.MediaLibrary.masterProbeInProgressRefresh' ) }} <span uk-spinner="ratio: 0.5" style="color: orange"> </span> </div>
            <div v-if="( isMediaProbeInProgress( master ) || isReprobeInProgress ) && !checkCreatedDate( master )" class="uk-text-left uk-margin-small"> {{ $t( 'Pages.MediaLibrary.masterProbeInProgress' ) }} <span uk-spinner="ratio: 0.5" style="color: orange"> </span> </div>
            <div v-if="bundle.masterVideo.transcodes.length > 0" class="uk-margin-medium-top">
                <BaseTable :tableHeaders="tableHeaders">
                <tr v-for="(transcode, i) in bundle.masterVideo.transcodes" :key="i">
                    <td>
                        {{ formatLabel( transcode ) }}
                    </td>
                    <td v-if="transcode.transcodeStatus === 'IN_PROGRESS' || transcode.transcodeType === 'CLEARVR'" class="resolution">
                        {{ $t( 'Generic.Labels.NotAvailable' ) }}
                    </td>
                    <td v-else>
                        {{ resolution( transcode.resolution ) }}
                    </td>
                    <td>
                        {{ transcodeStatusLabel( transcode.transcodeStatus, transcode.transcodeDateRange.start ) }}
                    </td>
                    <td>
                        {{ probeStatusLabel( transcode.probeStatus ) }}
                    </td>
                    <td>
                        {{ progressLabel( transcode.transcodeType, transcode.transcodeStatus, transcode.probeStatus ) }}
                    </td>
                    <td class="icons uk-padding-remove">
                        <i v-if="isMediaProbeSupport( transcode )" :uk-tooltip="$t('Actions.Reprobe')" class="bi bi-radar uk-margin-small-right" style="cursor: pointer;font-size: 1.2rem;vertical-align: middle;" @click="reprobeMedia(transcode)"></i>
                    </td>
                    <td class="icons uk-padding-remove"  v-if="featureSupport && featureSupport.transcoding">
                        <span class="uk-margin-small-right" :uk-tooltip="$t('Actions.Restart')" uk-icon="icon: refresh;" style="cursor: pointer;" @click="restartTranscode(transcode)"> </span>
                    </td>
                    <td class="icons uk-padding-remove">
                        <div class="uk-margin-small-right" uk-icon="icon: trash; ratio: 1" :uk-tooltip="$t('Actions.Delete')" style="cursor: pointer;" @click="doDeleteMedia(transcode)" />
                    </td>
                    <td class="icons uk-padding-remove">
                        <span uk-spinner="ratio: 0.6" style="color: orange" v-if="isInProgress( transcode.transcodeStatus ) || isInProgress( transcode.probeStatus )" > </span>
                        <span uk-icon="icon: warning;" style="color: #b71c1c;" v-if="isFailed( transcode.transcodeStatus ) || isFailed( transcode.probeStatus )"> </span>
                        <span v-if="isCompleted( transcode.transcodeStatus ) && isCompleted( transcode.probeStatus )" class="bi bi-tick" style="color: #72ae35;font-size: 1.2rem;vertical-align: middle"></span>
                    </td>
                </tr>
                </BaseTable>
            </div>
            <div class="uk-card-body uk-padding-remove uk-margin-medium-top uk-margin-medium-bottom">
                <div class="uk-grid uk-margin-medium-bottom">
                    <div v-for="(item,i) in bundle.media"  :key="i" class="uk-grid-item-match uk-margin-small-bottom"> 
                        <MediaFileCard :item="item" :show-move="false" :is-master="item.id === bundle.masterVideo.id" @refetch="fetchMediaBundle" />
                    </div>
                </div>
                <div>
                    <button v-if="!deleting" class="uk-button uk-button-primary uk-margin-small-right uk-align-right" type="button" @click="deleteBundle" > {{ $t( 'Actions.Delete' ) }} </button>
                    <button v-else class="uk-button uk-button-primary uk-margin-small-right uk-align-right" disabled><div uk-spinner="ratio: 0.5"></div></button>
                </div>
            </div>
        </div>
        <div container="#bundle" :id="'transcode-list-modal-'+bundle.id" uk-modal="bg-close: false; esc-close: false">
            <div class="uk-modal-dialog" >
                <h3 class="uk-text-left uk-modal-header uk-margin-remove-bottom">  {{ $t('Pages.MediaLibrary.Labels.SelectFormat') }} </h3>
                <div class="uk-modal-body uk-padding-small">
                    <ul class="uk-nav uk-dropdown-nav">
                        <li class="uk-text-left uk-text-default uk-margin-small-left" v-for="(item,i) in formats"  :key="i" > <input class="uk-checkbox uk-margin-small-left uk-margin-small-top uk-margin-small-bottom" type="checkbox" v-model="item.checked"> <span class="uk-margin-small-left" style="vertical-align: middle;"> {{ $t( `enums.MediaFormats.${item.transcode}` ) }} </span>
                        </li>
                    </ul>
                </div>
                <div class="uk-modal-footer uk-align-right uk-width-1-1">
                    <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button"> {{ $t('Actions.Cancel') }} </button>
                    <button class="uk-button uk-button-primary uk-margin-small-right" type="button" :disabled="disableTranscodeButton" @click="start" > {{ $t('Actions.Start') }} </button>
                </div>
            </div>
        </div>
        <div id="delete-media-bundle-modal" uk-modal="bg-close: false; esc-close: false">
            <div class="uk-modal-dialog uk-modal-body">
                <button class="uk-modal-close-default" type="button" uk-close></button>
                    <div class="uk-text-warning" uk-icon="icon: info; ratio: 2" />
                <p class="uk-text-meta">{{ $t('DeleteModal.CannotDeleteMedia') }}</p>
            </div>
        </div>
        <div container="#bundle" id="add-camera-modal" uk-modal="bg-close: false; esc-close: false">
            <div class="uk-modal-dialog uk-margin-auto-vertical">
                <div class="uk-modal-header">
                    <h3 class="uk-text-left uk-modal-title"> {{ $t( 'Pages.MediaLibrary.AddCamera' ) }} </h3>
                </div>
                <div class="uk-modal-body">
                    <label class="uk-text-meta uk-align-left uk-margin-remove"> {{ $t('Pages.MediaLibrary.Labels.Title') }} </label>
                    <div>
                        <input class="uk-input uk-margin-small-top" type="text" :placeholder="$t('Pages.MediaLibrary.Placeholders.Title')" v-model.trim="camera.title" />
                    </div>
                    <label class="uk-text-meta uk-align-left uk-margin-small-top uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Description') }} </label>
                    <div>
                        <textarea style="min-height: 80px" class="uk-input" type="text" :placeholder="$t('Pages.MediaLibrary.Placeholders.Description')" v-model.trim="camera.description"/>
                    </div>
                    <label class="uk-text-meta uk-align-left uk-margin-small-top uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.SourceURL') }} <span class="uk-text-danger">*</span> </label>
                    <div class="uk-inline uk-width-1-1">
                        <input class="uk-input" :class="{ 'invalid-border': camUrlError }" type="text" :placeholder="$t('Pages.MediaLibrary.URLPlaceholder')" v-model="camera.external" />
                        <span class="uk-form-icon uk-form-icon-flip invalid-icon" v-if="camUrlError" uk-icon="icon: warning" ></span>
                        <span class="uk-form-icon uk-form-icon-flip uk-width-auto uk-margin-medium-right inline-error-bg" v-if="!isUrlValid" >{{camUrlError}}</span>
                    </div>
                    <div class="uk-flex uk-width-1-1">
                        <div class="uk-width-1-2">
                            <label class="uk-text-meta uk-align-left uk-margin-small-top uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.ProjectionType') }} </label>           
                            <div class="uk-margin-small-top uk-margin-small-right">
                                <select class="uk-select" placeholder="Select" v-model="camera.projection">
                                    <option v-for="type in ProjectionType.values"  :key="type" :value="type">{{ $t( `enums.ProjectionType.${type}` ) }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="uk-width-1-2">
                            <label class="uk-text-meta uk-align-left uk-margin-small-top uk-margin-small-bottom"> {{ $t('Pages.MediaLibrary.Labels.Blindspot') }} </label>           
                            <div class="uk-margin-small-top">
                                <select class="uk-select" v-model="camera.blindspot">
                                    <option v-for="type in BlindspotType.values"  :key="type" :value="type">{{ $t( `enums.BlindspotType.${type}` ) }}</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="uk-modal-footer uk-width-1-1 uk-text-right">
                    <button class="uk-button uk-button-default uk-margin-small-right uk-modal-close" type="button" @click="cancelCameraCreation"> {{ $t('Actions.Cancel') }} </button>
                    <button v-if="!saving" class="uk-button uk-button-primary uk-margin-small-right" type="button" @click="createCamera" > {{ $t('Actions.Create') }} </button>
                    <button v-else class="uk-button uk-button-primary uk-margin-small-right" disabled><div uk-spinner="ratio: 0.5"></div></button>
                </div>
            </div>
        </div>
    </div>
    <div v-else >
        <span class="uk-position-center" uk-spinner> </span>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import { getMediaSizeBytes } from '@/utils/mediaItem';
import MediaFileCard from '@/components/cards/MediaFileCard.vue';
import BaseTable from '@/components/tables/BaseTable.vue';
import InputDatePicker, {dateFormat} from '@/components/InputDatePicker';
import IconicPlayer from '@/components/IconicPlayer';
import moment from 'moment';
import { MediaTranscodeType, ClearvrTranscodeJobEncodeQualityTypes, JobStatusType, MediaClass, ProjectionType, BlindspotType } from '@/utils/enums.js';
import { isTranscodableToClearvr, isTranscodableToMP4, getLabelFriendlyResolution, isMediaProbeSupport, getMediaTranscodesMap, isMediaProbeInProgress, isMediaTranscodeInProgress } from '@/utils/transcode.js';
import Notification from '@/components/util/Notification';

const CLEARVRencodeQualityTriple = 'CLEARVRencodeQualityTriple';

function mediaHasTranscode ( media, transcodeInfoType, quality ) {
    return media.transcodes?.some( ({ transcodeInfo }) => (
        transcodeInfo
        && transcodeInfo.quality === quality
        && transcodeInfo.__typename === transcodeInfoType
    ) );
}
let pollTheServerForMediaStatus = null;
let pollTheServerForMediaTranscodeStatus = null;
const MediaTypeNames = {
    CDNAsset: 'CDNAsset',
    ExternalAsset: 'ExternalAsset'
};

const validUrlPattern = /^(http(s)?:\/\/){1}(www\.){0,1}[a-zA-Z0-9\.\-]+\.[a-zA-Z]{2,5}[\.]{0,1}/; // eslint-disable-line

export default {
    name: 'MediaBundle',
    components: { MediaFileCard, BaseTable, InputDatePicker, IconicPlayer },
    props: {
        bundleId: { type: String, required: true }
    },
    data () {
      return {
          bundle: null,
          saving: false,
          deleting: false,
          MediaClass,
          ProjectionType,
          BlindspotType,
          formats: [],
          hide: false,
          edit: false,
          title: '',
          description: '',
          selectProjectionType: '',
          liveStartTime: '',
          liveEndTime: '',
          disableTranscodeButton: false,
          camera: {
              title: '',
              description: '',
              external: '',
              projection: ProjectionType.EQUIRECTANGULAR,
              blindspot: BlindspotType.NONE
          },
          tableHeaders: [{
            name: this.$t('Pages.MediaLibrary.Headers.Format'),
            class: ''
            },
            {
            name: this.$t('Pages.MediaLibrary.Headers.Resolution'),
            class: ''
            },
            {
            name: this.$t('Pages.MediaLibrary.Headers.Transcode'),
            class: ''
            },
            {
            name: this.$t('Pages.MediaLibrary.Headers.Probe'),
            class: ''
            },
            {
            name: this.$t('Pages.MediaLibrary.Headers.Progress'),
            class: ''
            },
            {
            name: this.$t('Actions.Actions'),
            class: '',
            colspan: 4
            }],
            polling: false,
            fetching: false,
            master: this.bundle?.masterVideo,
            camUrlError: false,
            orgId: this.$route.params.id,
            isReprobeInProgress:false
        }  
    },
    mounted () {
        this.fetchMediaBundle();
    },
    beforeDestroy () {
        clearInterval( pollTheServerForMediaStatus );
        clearInterval( pollTheServerForMediaTranscodeStatus );
    },
    computed: {
        ...mapState({
            clearVRConfig: state => state.venom.org.clearVRConfig,
            featureSupport: state => state.venom.org.options?.featureSupport
        }),
        minDate () {
            return moment().format( dateFormat );
        },
        isLive () {
            return this.media.mediaClass === MediaClass.LIVE;
        },
        isVideo () {
            return this.media.mediaClass === MediaClass.VIDEO;
        },
        minEndDate () {
            const dateType = typeof this.liveStartTime;
            if ( this.liveStartTime && dateType === "string" ) {
                const date = this.liveStartTime && new Date( this.liveStartTime );
                return moment( date?.setMinutes( date.getMinutes() + 1 ) ).format( dateFormat );
            }
            return moment().format( dateFormat );
        },
        MP4TranscodesLength () {
            return this.media.transcodes
                .filter( m => m.transcodeType === MediaTranscodeType.LOWER_RESOLUTION_MP4S ).length;
        },
        clearVREnabled () {
            const { apiAuthToken, customerId } = this.clearVRConfig;

            return Boolean( apiAuthToken && customerId );
        },
        media () {
            return this.bundle.masterVideo
        },
        internal () {
            return this.media.file?.__typename === MediaTypeNames.CDNAsset;
        },
        transcodableFormatList () {
            const mediaFormats = this.media.transcodes?.map( media => media.transcodeType );
            let availableFormats = MediaTranscodeType.values
                .filter( format => format !== MediaTranscodeType.CLEARVR && !mediaFormats?.includes( format ) );

            if ( !mediaHasTranscode( this.media, 'ClearVRTranscodeInfo', ClearvrTranscodeJobEncodeQualityTypes.TRIPLE ) && this.clearVREnabled ) {
                availableFormats.push( CLEARVRencodeQualityTriple );
            }

            // remove clearvr formats
            if ( !isTranscodableToClearvr( this.media.resolution, this.media.projection ) || !this.internal ) {
                availableFormats = availableFormats.filter( format => !/^CLEARVR/.test( format ) );
            }
            if ( !isTranscodableToMP4( this.media.resolution ) || !this.internal ) {
                availableFormats = availableFormats.filter( format => format !== 'LOWER_RESOLUTION_MP4S' );
            }
            
            return availableFormats;
        },
        hasLinkedContentOrAssets () {
            return this.media?.assets?.length > 0 || this.media?.linkedContent?.length > 0;
        },
        isEmptyStartTime () {
            return this.liveStartTime === '' || this.liveStartTime === null ;
        },
        validateLiveTimes () {
            return this.liveStartTime!==''&& this.liveStartTime && this.liveEndTime && this.liveStartTime >= this.liveEndTime;
        },
        invalidName () {
            if ( this.title.length >= 0 && this.title.length < 1 ) {
                return false;
            }
            if ( !this.title.trim() && this.title.length > 0 )
                return false;
            return true;
        },
        isFormValid () {
            if ( !this.invalidName )
                return false;
            if ( this.isLive && this.isEmptyStartTime )
                return false;
            if ( this.isLive && this.validateLiveTimes )
                return false;
            return true;
        },
        isUrlValid () {
            if (!this.camUrlError && !this.camera.external) return true
            this.validateUrl()
            return !this.camUrlError;
        },
        mediaAssets () {
            if( this.media?.assets?.length > 0 ) {
                this.media?.assets?.forEach( (a,i) => {
                    if( a.owner === null ) {
                        this.media?.assets.splice(i,1);         
                    }
                });
                return this.media?.assets;
            }
            return [];
        }
    },
    methods: {
        assetRedirection ( asset ) {
            if ( asset.owner && asset.owner.__typename === 'VodRef' )
                return { name: 'ContentDetails', params: { id: this.orgId, propertyId: asset.owner?.primaryFormat?.content?.collection?.property?.id, contentId: asset.owner?.primaryFormat?.content?.id } };
            else 
                return { name: 'AppEdit', params: { id: this.orgId, appId: asset.owner?.id }, query: { tab: 'assets' } }
        },
        validateUrl () {
            if (validUrlPattern.test( this.camera.external )) {
                this.camUrlError = false
            } else if (!validUrlPattern.test( this.camera.external ) && this.camera.external.length > 1) {
                this.camUrlError = this.$t('Pages.MediaLibrary.InvalidURL')
            }
        },
        validateCameraForm () {
            let isValid = true
            if (!this.camera.external.length || !this.isUrlValid) {
                isValid = false
                this.camUrlError = this.$t('Pages.MediaLibrary.InvalidURL')
            }
            return isValid
        },
        clicked () {
            this.formats = [];
            this.hide = true;
            const test = this.transcodableFormatList;
            for ( const transcodeType of test ) {
                if (!this.featureSupport.clearVR && transcodeType.includes('CLEARVR')) continue
                this.formats.push({ checked: false, transcode: transcodeType });
            }
        },
        async fetchMediaBundle () {
            this.bundle = await this.$store.dispatch('fetchMediaBundle', { bundleId: this.bundleId } );
            let transcodeArr =  this.bundle?.masterVideo?.transcodes;
            transcodeArr && transcodeArr.some((item)=>{
                if( isMediaProbeInProgress( item ) ){
                    this.checkForProbe();
                    return true;
                }
           });

            transcodeArr && transcodeArr.some((item)=>{
                if( !isMediaTranscodeInProgress( item )){
                    this.checkForTranscode();
                    return true;
                }
            }) 
            this.title = this.bundle?.name;
            this.description = this.bundle?.masterVideo?.description || '';
            this.selectProjectionType = this.bundle?.masterVideo?.projection;
            this.liveStartTime = this.bundle?.masterVideo?.liveStartTime;
            this.liveEndTime = this.bundle?.masterVideo?.liveEndTime;
        },
        getMediaSizeBytes,
        isMediaProbeSupport,
        isMediaProbeInProgress,
        async transcodeMedia () {
            window.UIkit.modal(`#transcode-list-modal-${this.bundle.id}`).show();
            this.clicked();
        },
        formatLabel ( media ) {
            const transcodeLabel = this.$t( `transcodeFormat.${media.transcodeType}` );
            if ( transcodeLabel === 'ClearVR' ) {
                const encodeQualityLabel = media.transcodeOptions?.encodeQuality;
                return transcodeLabel + ( encodeQualityLabel ? ` (${encodeQualityLabel})` : '' );
            }
            return transcodeLabel;
        },
        resolution ( resolution ) {
            return getLabelFriendlyResolution( resolution );
        },
        transcodeStatusLabel ( status, time ) {
            switch ( status ) {
            case 'IN_PROGRESS':
                return `Started ${moment( time ).startOf( 'minute' ).fromNow()}`;
            case 'COMPLETED':
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.COMPLETED' );
            case 'ERROR':
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.ERROR' );
            default:
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.NONE' );
            }
        },
        probeStatusLabel ( status ) {
            switch ( status ) {
            case 'IN_PROGRESS':
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.IN_PROGRESS' );
            case 'COMPLETED':
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.COMPLETED' );
            case 'ERROR':
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.ERROR' );
            default:
                return this.$t( 'Pages.MediaLibrary.TranscodeStatus.NONE' );
            }
        },
        isFailed ( status ) {
            return status === JobStatusType.ERROR;
        },
        isInProgress ( status ) {
            return status === JobStatusType.IN_PROGRESS;
        },
        isCompleted ( transcodeStatus ) {
            return transcodeStatus === JobStatusType.COMPLETED;
        },
        progressLabel ( transcodeType, transcodeStatus, probeStatus ) {
            if ( probeStatus === JobStatusType.NONE ) {
                return this.isCompleted( transcodeStatus ) ? `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeComplete' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeNotAvailable' )}`
                    : ( this.isInProgress( transcodeStatus ) ? `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeInProgress' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeNotAvailable' )}`
                        : `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeFail' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeNotAvailable' )}` );
            }

            if ( this.isCompleted( transcodeStatus ) && this.isCompleted( probeStatus ) )
                return this.$t( 'Pages.MediaLibrary.ProgressLabels.complete' );

            if ( this.isInProgress( transcodeStatus ) && this.isInProgress( probeStatus ) )
                return this.$t( 'Pages.MediaLibrary.ProgressLabels.inProgress' );

            if ( this.isFailed( transcodeStatus ) && this.isFailed( probeStatus ) )
                return this.$t( 'Pages.MediaLibrary.ProgressLabels.fail' );

            if ( this.isCompleted( transcodeStatus ) && this.isInProgress( probeStatus ) )
                return `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeComplete' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeInProgress' )}`;

            if ( this.isCompleted( transcodeStatus ) && this.isFailed( probeStatus ) )
                return `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeComplete' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeFailed' )}`;

            if ( this.isInProgress( transcodeStatus ) && this.isFailed( probeStatus ) )
                return `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeInProgress' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeFailed' )}`;

            if ( this.isInProgress( transcodeStatus ) && this.isCompleted( probeStatus ) )
                return `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeInProgress' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeComplete' )}`;

            if ( this.isFailed( transcodeStatus ) && this.isInProgress( probeStatus ) )
                return `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeFail' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeInProgress' )}`;

            if ( this.isFailed( transcodeStatus ) && this.isCompleted( probeStatus ) )
                return `${this.$t( 'Pages.MediaLibrary.ProgressLabels.transcodeFail' )} / ${this.$t( 'Pages.MediaLibrary.ProgressLabels.probeComplete' )}`;

            return JobStatusType.NONE;
        },
        async reprobeMaster () {
            window.UIkit.modal.confirm(this.$t( 'Pages.MediaLibrary.confirmReprobe'), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Reprobe')
            }}).then( async () => {
            await this.$store.dispatch('reprobeMedia',{ media: this.bundle.masterVideo.id });
            this.isReprobeInProgress = true;
            this.checkForProbe();
            },() => {
            });
        },
        async reprobeMedia ( media ) {
            window.UIkit.modal.confirm(this.$t( 'Pages.MediaLibrary.confirmReprobe'), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Reprobe')
            }}).then( async () => {
            await this.$store.dispatch('reprobeMedia',{ media: media.id });
            await this.fetchMediaBundle();
            },() => {
            });
        },
        async checkForProbe () {
            pollTheServerForMediaStatus = setInterval( async () => {
                if ( !this.polling ) {
                    this.polling = true;
                    const bundle = await this.$store.dispatch('fetchMediaBundle', { bundleId: this.bundleId } );
                    if ( bundle ) {
                        this.polling = false;
                        this.master = bundle?.masterVideo;
                        if ( !isMediaProbeInProgress( this.media ) ) {
                            this.isReprobeInProgress = false;
                            clearInterval( pollTheServerForMediaStatus );
                        }
                    }
                }
            }, 10000 );
        },
        async checkForTranscode () {
         const   pollTheServerForMediaTranscodeStatus = setInterval( async () => {
                if ( !this.reTranscode ) {
                    this.reTranscode = true;
                    let inprogressList = [];
                    this.bundle= await this.$store.dispatch('fetchMediaItemStatus', { bundleId: this.bundleId } );
                    if ( this.bundle ) {
                        this.reTranscode = false;
                        this.bundle.masterVideo?.transcodes.forEach ( transcode => {
                            if ( (isMediaTranscodeInProgress( transcode ) || isMediaProbeInProgress( transcode ))) {
                                inprogressList.push(transcode);
                            }
                        })
                        if ( inprogressList.length === 0 ){
                            //last request to fetch full information now
                            this.bundle= await this.$store.dispatch('fetchMediaBundle', { bundleId: this.bundleId } );
                            this.master = this.bundle?.masterVideo;
                            clearInterval( pollTheServerForMediaTranscodeStatus );
                        }
                    }
                    inprogressList = [];
                }
            }, 60000 );
        },
        async restartTranscode ( media ) {
            window.UIkit.modal.confirm(this.$t( 'Pages.MediaLibrary.confirmRestart'), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Transcode')
            }}).then( async () => {
            this.fetching = true;
            await this.$store.dispatch('reTranscode',{ mediaId: media.id, orgId: this.orgId });
            await this.fetchMediaBundle();
            this.fetching = false;
            },() => {
            });
        },
        async start () {     
            const parseEncodeQuality = format => {
                if ( format === CLEARVRencodeQualityTriple )
                    return ClearvrTranscodeJobEncodeQualityTypes.TRIPLE;

                return null;
            };
            let atleastOneChecked = false;
            const parseEncodeFormat = format => ( /^CLEARVR/i.test( format ) ? 'CLEARVR' : format );
            for ( const format of this.formats ) {
                if( format.checked ) {
                    this.disableTranscodeButton = true;
                    atleastOneChecked = true;
                    await this.$store.dispatch('transcodeMedia', { media: this.bundle.masterVideo.id, targetFormat: parseEncodeFormat( format.transcode ),clearvrEncodeQuality: parseEncodeQuality( format.transcode )  } );
                }
            }
            if( atleastOneChecked ) {
                window.UIkit.modal(`#transcode-list-modal-${this.bundle.id}`).hide();
               await this.fetchMediaBundle();
            }
            this.disableTranscodeButton = false;
        },
        deleteBundle () {
        if ( this.media.linkedContent?.length ) {
            window.UIkit.modal('#delete-media-bundle-modal').show();
        } else {
                window.UIkit.modal.confirm(this.$t( 'DeleteModal.ConfirmDeleteMedia', { name: this.bundle.name }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
                this.deleting = true
                const response = await this.$store.dispatch('deleteMediaBundle', { mediaId: this.bundle.id });
                this.deleting = false
                    if ( !response.errors ) {
                        Notification.showNotification(this.$t( 'DeleteModal.Removed'), this.$t( 'DeleteModal.HasBeenRemoved', { name: this.bundle.name }));
                    this.$router.go(-1);
                    } else {
                        Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), response?.errors[0]?.message,'error');
                    }
                },() => {
      });
            }
        },
        isTranscodeDeletable ( media ) {
            if( this.isFailed(media.transcodeStatus) )
                return true;
            return !this.media?.linkedContent?.length;
        },
        // different behaviour for transcodeTypes,
        // for mp4 and hls, deletes all mp4 items
        // for clearvr, delete specific clearvr item
        async doDeleteMedia ( media ) {
            if ( !this.isTranscodeDeletable( media ) ) {
                window.UIkit.modal('#delete-media-bundle-modal').show();
            } else {
                const { transcodeType } = media;
                window.UIkit.modal.confirm( transcodeType === ( MediaTranscodeType.LOWER_RESOLUTION_MP4S) || transcodeType === ( MediaTranscodeType.HLS_ABR) ? this.$t( 'DeleteModal.ConfirmDeleteAllMedia', { name: this.$t(`transcodeFormat.${transcodeType}`) }) : this.$t( 'DeleteModal.ConfirmDeleteTranscode', { name: media.title }), { labels: {
                cancel: this.$t('Actions.Cancel'), ok: this.$t('Actions.Delete')
            }}).then( async () => {
                    if ( !this.isFailed( media.transcodeStatus ) ) {
                        if ( transcodeType === MediaTranscodeType.CLEARVR ) {
                            this.deleteMediaList = [ media.id ];
                        } else {
                            this.deleteMediaList = Array.from( getMediaTranscodesMap( this.media )[transcodeType]).map( mediaItem => mediaItem.id );
                        }
                    } else {
                        this.deleteMediaList = [ media.id ];
                    }
                    try {
                        await Promise.all( this.deleteMediaList.map( async media => {
                            await this.$store.dispatch('deleteMedia', { mediaId: media });
                        }) );
                        this.fetchMediaBundle();
                    } catch ( err ) {
                        Notification.showNotification(this.$t( 'DeleteModal.NotRemoved'), this.$t( 'DeleteModal.CouldNotRemove', { name: media.title }, false));
                    }
                },() => {
      });
            }
        },
        async updateMediaBundle () {
            if (!this.isFormValid || this.saving) return
            this.saving = true
            const response = await this.$store.dispatch('updateMediaBundle',{ variables: { folder: this.bundle.id, name: this.title, projection: this.selectProjectionType }})
            await this.$store.dispatch('updateMedia',{variables: { media: this.bundle.masterVideo.id, description: this.description, liveStartTime: this.liveStartTime, liveEndTime: this.liveEndTime }})
            this.saving = false
            if( response ) {
                Notification.showNotification(this.$t('Actions.Updated'), this.$t('Pages.MediaLibrary.MediaUpdatedSuccessfully'));
                this.fetchMediaBundle();
                this.edit = false;
           }
        },
        cancel () {
            this.edit = false;
            this.title = this.bundle.name;
            this.description = this.bundle.masterVideo.description;
            this.selectProjectionType = this.bundle.masterVideo.projection;
            this.liveStartTime = this.bundle.masterVideo.liveStartTime;
            this.liveEndTime = this.bundle.masterVideo.liveEndTime;
        },
        cancelCameraCreation () {
            this.camera = {
              title: '',
              description: '',
              external: '',
              projection: ProjectionType.EQUIRECTANGULAR,
              blindspot: BlindspotType.NONE
            }
            this.camUrlError = false
        },
        async createCamera () {
            if (!this.validateCameraForm()) { return false }
            this.saving = true;
            const variables =  { bundle: this.bundle.id, title: this.camera.title, external: this.camera.external, description: this.camera.description, projection: this.camera.projection, blindspot: this.camera.blindspot, mediaClass: MediaClass.LIVE }
            const response  = await this.$store.dispatch('createMedia', variables );
            if( response ) {
                this.saving = false;
                window.UIkit.modal('#add-camera-modal').hide();
                Notification.showNotification(this.$t( 'Actions.Created'), this.$t( 'Pages.MediaLibrary.CameraCreated', { name: this.camera.title }));
                this.fetchMediaBundle();
                this.cancelCameraCreation();
            }
        },
        async checkCreatedDate( master ) {
            if(!master){
                return false;
            }
            const today = moment(master.createdAt);
            const dateToCheck = moment().subtract(1, 'd');
            return today.diff(dateToCheck, 'd') > 1;
        },
        endTimeInput ( date ) {
            if ( Object.keys( date ).length === 0 ) {
              this.liveEndTime = null;
            } 
        },
        startTimeInput ( date ) {
            if ( Object.keys( date ).length === 0 ) {
              this.liveStartTime = null;
            } 
        }
    }
}
</script>

<style lang="scss" scoped>
.icons {
    width: 35px;
}

.format {
    border-top: 1px solid var(--app-primary-color);
    border-left: 1px solid var(--app-primary-color);
    border-right: 1px solid var(--app-primary-color);
    background-color: white;
}

.format:last-child {
  border-bottom: 1px solid var(--app-primary-color);
}

.iconic-player {
    width: 600px;
    height: 365px;
}

.name-overflow {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.media-header-text .uk-icon {
    line-height: normal;
}
</style>
